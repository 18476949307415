@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400&display=swap');

:root {
  --footer-height: 32px;
}

.loading {
  display: flex;
  align-content: center;
  justify-content: center;
}

.loading-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .loading-logo {
    animation: loading-spin infinite 20s linear;
  }
}

.loading-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}
@media screen and (min-width: 1500px) {
  section {
    max-width: 1200px;
  }
}

section.loading {
  text-align: center;
  margin: 100px auto;
  width: 100%;
}

section strong {
  color: #555;
}

footer {
  color: #333;
  border-top: 1px solid #ccc;
  height: var(--footer-height);
  /* text-align: center; */
  /* vertical-align: middle; */
  font-size: 10px;
  display: flex;
  flex-flow: row nowrap;
}
footer span {
  display: flex;
  padding: 4px 8px;
}
footer span:first-child {
  flex-grow: 1;
}
footer a {
  color: #333;
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}
pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.dml-widget-container {
  border: 1px solid #ddd !important;
}

.hidden-collection .dml-widget-container {
  border: none !important;
}

/* Firefox old*/
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink-image {
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}
